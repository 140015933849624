import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modals',
  templateUrl: './find.component.html'
})
export class FindComponent implements OnInit {
  @Input() public user;
  error: any;
  @Output() statusOpen: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  action: any;

  @Input() data: string;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) { }


  // modal Open Form
  modalOpenForm(modalForm) {
    this.modalService.open(modalForm);
  }
  ngOnInit(): void {
    this.action = this.data;

  }



  saveAction() {
    // this.loading = true;
    // let payload = this.patientService.createPatientProblemListForm.value;
    // payload['patient_id'] = this.user;
    // if (this.action == 'add') {
    //   this.createAction(payload);
    // }
    // else {
    //   this.updateAction(payload);
    // }
  }

}
