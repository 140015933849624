<div *ngIf="!loader">
   <div class="product-detail w-100 ">
      <div class="row">
         <div class="col-lg-9">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
            <a [routerLink]="['/shop/product/left/sidebar/', product?.slug]">
               <h6>{{ product?.name | titlecase }}</h6>
            </a>
            <p [innerHTML]="product.details"></p>
         </div>

         <div class="col-3 ">
            <h4>
               <div class="fs-4 ">
                  {{ product?.unit_price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                  <del *ngIf="product?.discount"><span class="money"> {{ product?.unit_price * currency?.price |
                        currency:currency?.currency:'symbol' }}</span></del>
               </div>
            </h4>
            <div class=" btn btn-solid btn-sm1 mt-2">
               <a href="javascript:void(0)" class="text-light" title="Add to cart"
                  (click)="CartModal.openModal(product)" *ngIf="cartModal">
                  <i class="ti-shopping-cart"></i> add to card
               </a>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- <div *ngIf="!loader && demo"> -->
  <!-- <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" class="img-fluid lazy-loading"
          alt="{{ product.images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading"
          alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div> -->
  <!-- <div class="product-detail w-100 ">

      <div  class="row">
     <div class="col-lg-9">
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
        <h6>{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
     </div> 

<div class="col-3 mt-5 ">
      <h4>
        <div class="fs-4 ">
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price |
            currency:currency?.currency:'symbol' }}</span></del>
          </div>
      </h4>
      <div class=" btn btn-solid btn-sm1 mt-2">
      <a  href="javascript:void(0)" class="text-light" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        <i class="ti-shopping-cart"></i> add to card
      </a>
    </div>
    </div> -->
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
    <!-- </div>

  </div>
</div> -->
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>