import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = environment.apiUrl;
    private tokenKey = 'authToken';
    private loggedIn = new BehaviorSubject<boolean>(this.hasToken());

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    private hasToken(): boolean {
        return !!localStorage.getItem(this.tokenKey);
    }

    get isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    signIn(credentials: { email: string; password: string }): Observable<any> {
        return this.http.post<{ token: string }>(`${this.apiUrl}auth/login`, credentials)
            .pipe(
                tap(response => {
                    localStorage.setItem(this.tokenKey, response.token);
                    this.loggedIn.next(true);
                }),
                catchError(this.handleError)
            );
    }

    logout(): void {
        localStorage.removeItem(this.tokenKey);
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
    }

    getToken(): string | null {
        return localStorage.getItem(this.tokenKey);
    }

    //   isTokenExpired(): boolean {
    //     const token = this.getToken();
    //     return token ? this.jwtHelper.isTokenExpired(token) : true;
    //   }

    private handleError(error: HttpErrorResponse) {
        return throwError(() => error);
    }
}
